/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import { Box, BrickLoader } from "@nordcloud/gnui";
import { ResourceStatus } from "~/generated/graphql";
import { ChangeDetails } from "~/components/ChangeDetails";
import { Details } from "~/components/Details";
import { ErrorWrap } from "~/components/Error";
import { LoaderWrap } from "~/components/Loader";
import { BorderWrapper } from "~/components/Styled";
import { ResourceDetailsBreadcrumbs } from "~/views/resources/ResourceDetails/components/ResourceDetailsBreadcrumbs";
import { ResourceDetailsStatus } from "~/views/resources/ResourceDetails/components/ResourceDetailsStatus";
import {
  DetailsColumnFirst,
  DetailsColumnSecond,
  HeaderPresetResource,
} from "~/views/resources/ResourceDetails/presets";
import { useGetResource } from "../hooks";
import { ResourceCtxProvider } from "../ResourceProvider";
import { Tabs } from "./Tabs/Tabs";

type ResourceRouteParams = {
  resourceId: string;
};

export function ResourceDetails() {
  const { resourceId } = useParams<ResourceRouteParams>();
  const { resource, error, loading } = useGetResource({ resourceId });

  return (
    <ResourceCtxProvider value={{ resource }}>
      <Row>
        <Col>
          <ResourceDetailsBreadcrumbs
            title={resource?.name ?? resourceId ?? ""}
          />
        </Col>
      </Row>
      <Box spacing="spacing02">
        <LoaderWrap loading={loading} inContent>
          <ErrorWrap error={error}>
            <Row>
              <Col xs={8} sm={8} md={5} lg={8}>
                <BorderWrapper
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ResourceDetailsStatus
                    os={resource?.os}
                    status={
                      resource?.state
                        ? resource?.state.status
                        : ResourceStatus.ConnectionLost
                    }
                  />
                  <div css={{ width: "100%" }}>
                    <Details details={HeaderPresetResource(resource)} />
                  </div>
                </BorderWrapper>
                <Row>
                  <Col>
                    <Details details={DetailsColumnFirst(resource)} />
                  </Col>
                  <Col>
                    <Details details={DetailsColumnSecond(resource)} />
                  </Col>
                </Row>
              </Col>
              <Col xs={8} sm={8} md={3} lg={4}>
                <ChangeDetails details={resource?.changeDetails} />
              </Col>
            </Row>
          </ErrorWrap>
        </LoaderWrap>
      </Box>
      <LoaderWrap loading={loading} Component={BrickLoader}>
        <Tabs />
      </LoaderWrap>
    </ResourceCtxProvider>
  );
}
