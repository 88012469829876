/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { TimeUnits } from "../types";

export const convertToMinutes = (value: number, unit: string) => {
  switch (unit) {
    case TimeUnits.hours: {
      return value * 60;
    }
    case TimeUnits.days: {
      return value * 60 * 24;
    }
    case TimeUnits.minutes: {
      return value;
    }
    default:
      return 0;
  }
};
