/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, ExtendedTooltip } from "@nordcloud/gnui";
import { OsSubType, OsType } from "~/generated/graphql";
import { SvgName } from "~/tools/types";

type Props = {
  os: {
    subType?: string | null;
    type?: string | null;
  };
};

export function OSCell({ os }: Props) {
  const toolTipText = os.subType || os.type || "No Data Available";

  const icon = os.subType || os.type || "cloudQuestionMark";
  return (
    <>
      <Wrap>
        <ExtendedTooltip caption={toolTipText} position="start">
          <SVGIcon size="md" name={getOsIcon(icon)} />
        </ExtendedTooltip>
      </Wrap>
    </>
  );
}

export function getOsIcon(os: string): SvgName {
  switch (os) {
    case OsType.Windows:
      return "windows";
    case OsSubType.WindowsServer:
      return "windows";
    case OsType.Linux:
      return "linux";
    case OsSubType.Redhat:
      return "redHat";
    case OsSubType.Amazon:
      return "amazonLinux";
    case OsSubType.Centos:
      return "centOS";
    case OsSubType.Debian:
      return "linux";
    case OsSubType.OracleLinux:
      return "oracle";
    case OsSubType.Suse:
      return "suse";
    case OsSubType.Ubuntu:
      return "ubuntu";
    default:
      return "cloud";
  }
}

const Wrap = styled.div`
  position: relative;
`;
