/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { Input, Label, Textarea } from "@nordcloud/gnui";
import { FormGroup, Metadata, MetadataSchema } from "~/components";
import { safeStr, stringRequired } from "~/components/Forms";
import { PlanField } from "../constants";
import { FormData } from "../formConfig";
import { usePlanWizard } from "../PlanProvider";
import { FormField } from "../types";

type Props = {
  nextStep: () => void;
};

export const DetailsSchema = z.object({
  [FormField.NAME]: stringRequired("Name"),
  [FormField.DESCRIPTION]: safeStr.optional(),
  [FormField.METADATA]: MetadataSchema.optional(),
});

export function PlanDetailsForm({ nextStep }: Props) {
  const { planData } = usePlanWizard();

  const formMethods = useForm({
    resolver: zodResolver(DetailsSchema),
    defaultValues: {
      [FormField.NAME]: planData?.details?.name ?? "",
      [FormField.DESCRIPTION]: planData?.details?.description ?? "",
      [FormField.METADATA]: planData?.details?.metadata ?? undefined,
    },
  });
  const { setPlanData } = usePlanWizard();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const submit: SubmitHandler<FormData> = (formData) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.DETAILS]: formData,
    }));

    nextStep();
  };

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Row>
          <Col>
            <FormProvider {...formMethods}>
              <form id="planDetailsForm" onSubmit={handleSubmit(submit)}>
                <FormGroup error={errors[FormField.NAME]}>
                  <Label name="Plan Name" required />
                  <Input id="name" {...register(FormField.NAME)} />
                </FormGroup>
                <FormGroup error={errors[FormField.DESCRIPTION]}>
                  <Label name="Plan Description" htmlFor="description" />
                  <Textarea
                    id="description"
                    {...register(FormField.DESCRIPTION)}
                  />
                </FormGroup>
                <Metadata />
              </form>
            </FormProvider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
