/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { Case, Switch } from "react-if";
import { z } from "zod";
import { theme, Box } from "@nordcloud/gnui";
import { stringRequired } from "~/components/Forms";
import { Schedule } from "~/views/plans/types";
import { PlanField } from "../constants";
import { FormData } from "../formConfig";
import { usePlanWizard } from "../PlanProvider";
import { FormField } from "../types";
import { isValidCronExpression } from "./NextDateCron";
import { ScheduleCron } from "./ScheduleCron";
import { ScheduleTimezone } from "./ScheduleTImezone";
import { SelectSchedule } from "./SelectSchedule";

type Props = {
  nextStep: () => void;
};

export const scheduleSchema = z.object({
  [FormField.SCHEDULE_TIMEZONE]: stringRequired("Schedule Timezone"),
  [FormField.SCHEDULE_OFFSET]: z.preprocess((input) => {
    const processed = z
      .string()
      .regex(/^-?[0-9]\d*(\.\d+)?$/)
      .transform(Number)
      .safeParse(input);
    return processed.success ? processed.data : input;
  }, z.number().int()),
  [FormField.SCHEDULE_OFFSET_UNIT]: stringRequired("Unit"),
  [FormField.SCHEDULE_CRON]: z.custom(
    (data) => isValidCronExpression(`${data}`),
    "Not Valid Cron Expression"
  ),
});

export function PlanScheduleForm({ nextStep }: Props) {
  const [scheduleType, setScheduleType] = useState(Schedule.repeated);

  const { planData, setPlanData } = usePlanWizard();

  const formMethods = useForm({
    resolver: zodResolver(scheduleSchema),
    defaultValues: {
      [FormField.SCHEDULE_TIMEZONE]:
        planData?.schedule_plan?.scheduleTimezone ?? "",
      [FormField.SCHEDULE_OFFSET]: planData?.schedule_plan?.scheduleOffset ?? 0,
      [FormField.SCHEDULE_OFFSET_UNIT]: "minutes",
      [FormField.SCHEDULE_CRON]: planData?.schedule_plan?.scheduleCron ?? "",
    },
  });

  const { handleSubmit } = formMethods;

  const submit: SubmitHandler<FormData> = (formData) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: formData,
    }));

    nextStep();
  };

  const handleScheduleChange = (scope: Schedule) => {
    setScheduleType(scope);
  };
  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        Schedule Options
        <Box
          boxStyle="lightGrey"
          spacing="spacing04"
          mt={theme.spacing.spacing04}
        >
          <FormProvider {...formMethods}>
            <form id="planScheduleForm" onSubmit={handleSubmit(submit)}>
              <SelectSchedule onScopeChange={handleScheduleChange} />
              <Switch>
                <Case condition={scheduleType === Schedule.repeated}>
                  <ScheduleTimezone />
                  <ScheduleCron setPlanData={setPlanData} />
                </Case>
                <Case condition={scheduleType === Schedule.runOnce}>
                  <ScheduleTimezone />
                </Case>
                <Case condition={scheduleType === Schedule.runManually}>
                  <div />
                </Case>
              </Switch>
            </form>
          </FormProvider>
        </Box>
      </Col>
    </Row>
  );
}
