/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useReducer, useState } from "react";
import {
  Button,
  FlexContainer,
  SVGIcon,
  Tab,
  Tabs,
  theme,
} from "@nordcloud/gnui";
import { WizardButton, WrapWizardButton } from "~/components";
import { showError } from "~/services/toast";
import { isEmpty } from "~/tools";
import { useCreatePlan } from "~/views/plans/hooks/useCreatePlan/useCreatePlan";
import { ConfirmAndFinalize } from "./ConfirmAndFinalize";
import { defaultValues, PlanData, PlanField } from "./constants";
import { PlanDetailsForm } from "./PlanDetailsForms";
import { GeneralNotificationWrapper } from "./PlanGeneralNotificationForms/GeneralNotificationWrapper";
import { PlanWizardCtxProvider } from "./PlanProvider";
import { PlanScheduleForm } from "./PlanScheduleForms/PlanScheduleForm";
import { PlanSettingsForm } from "./PlanSettingsForms/PlanSettingsForm";
import { State } from "./types";

const defaultState = { currentStep: 0, subStep: 0, planId: "" };

export function PlanCreateWizard() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    defaultState
  );

  const [planData, setPlanData] = useState<PlanData>(defaultValues);

  const handleSuccess = (id: string) => updateState({ planId: id, subStep: 1 });
  const { createPlan, loading } = useCreatePlan({
    onSuccess: handleSuccess,
  });

  const handleCreatePlan = () => createPlan({ ...planData });

  const isNextStepAllowed =
    state.currentStep < 2 || isEmpty(planData[PlanField.ERRORS] ?? []);

  const nextStep = () => {
    if (isNextStepAllowed) {
      updateState({ currentStep: state.currentStep + 1 });
    } else {
      handlePlanErrors();
    }
  };

  const handlePlanErrors = () => {
    showError(
      <div>
        {"Please verify plan settings:"}
        {(planData?.[PlanField.ERRORS] ?? []).map((error) => {
          return <div key={error}> {`- ${error}`}</div>;
        })}
      </div>
    );
  };

  const previousStep = () =>
    updateState({ currentStep: state.currentStep - 1 });
  const handleSteps = (key: number) => updateState({ currentStep: key });
  const buttons = {
    step1: (
      <WrapWizardButton>
        <WizardButton form="planDetailsForm">
          <FlexContainer gap={theme.spacing.spacing02}>
            Next Step
            <SVGIcon name="arrowRight" size="sm" />
          </FlexContainer>
        </WizardButton>
      </WrapWizardButton>
    ),
    step2: (
      <WrapWizardButton>
        <Button
          icon="arrowLeft"
          severity="low"
          onClick={() => {
            previousStep();
          }}
        >
          Previous Step
        </Button>
        <WizardButton form="planScheduleForm">
          <FlexContainer gap={theme.spacing.spacing02}>
            Next Step
            <SVGIcon name="arrowRight" size="sm" />
          </FlexContainer>
        </WizardButton>
      </WrapWizardButton>
    ),
    step3: (
      <WrapWizardButton>
        <Button
          icon="arrowLeft"
          severity="low"
          onClick={() => {
            previousStep();
          }}
        >
          Previous Step
        </Button>
        <WizardButton form="planSettingsForm">
          <FlexContainer gap={theme.spacing.spacing02}>
            Next Step
            <SVGIcon name="arrowRight" size="sm" />
          </FlexContainer>
        </WizardButton>
      </WrapWizardButton>
    ),
    step4: (
      <WrapWizardButton>
        <Button
          icon="arrowLeft"
          severity="low"
          onClick={() => {
            previousStep();
          }}
        >
          Previous Step
        </Button>
        <WizardButton form="planGeneralNotificationForm">
          <FlexContainer gap={theme.spacing.spacing02}>
            Next Step
            <SVGIcon name="arrowRight" size="sm" />
          </FlexContainer>
        </WizardButton>
      </WrapWizardButton>
    ),
    step5: (
      <WrapWizardButton>
        <Button
          icon="arrowLeft"
          severity="low"
          onClick={() => {
            previousStep();
          }}
        >
          Previous Step
        </Button>
        <WizardButton
          type="submit"
          onClick={handleCreatePlan}
          disabled={loading}
          initialState={loading ? "loading" : ""}
        >
          Create Plan
        </WizardButton>
      </WrapWizardButton>
    ),
  };

  return (
    <PlanWizardCtxProvider value={{ planData, setPlanData }}>
      <Tabs step={state.currentStep} wizard handleTab={handleSteps}>
        <Tab disabled heading="Plan Details" buttons={buttons.step1}>
          <PlanDetailsForm {...{ nextStep }} />
        </Tab>
        <Tab disabled heading="Schedule Plan" buttons={buttons.step2}>
          <PlanScheduleForm {...{ nextStep }} />
        </Tab>
        <Tab disabled heading="Plan Settings" buttons={buttons.step3}>
          <PlanSettingsForm {...{ nextStep }} />
        </Tab>
        <Tab
          disabled
          heading="General Plan Notifications"
          buttons={buttons.step4}
        >
          <GeneralNotificationWrapper {...{ nextStep }} />
        </Tab>
        <Tab
          disabled
          heading="Confirm and Finalize"
          buttons={state.subStep === 0 && buttons.step5}
        >
          <ConfirmAndFinalize
            planId={state.planId}
            updateState={updateState}
            step={state.subStep}
          />
        </Tab>
      </Tabs>
    </PlanWizardCtxProvider>
  );
}
