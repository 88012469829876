/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { v4 } from "uuid";
import {
  Box,
  Button,
  FlexContainer,
  Modal,
  StickyBar,
  theme,
} from "@nordcloud/gnui";
import {
  ExecutionPolicy,
  PlanActionNotificationTriggerType,
  PlanActionType,
} from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty } from "~/tools";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import {
  PlanField,
  maxInAdvance,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { isAdvanceTrigger } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/components/utils";
import { TimeUnits } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { convertToMinutes } from "~/views/plans/utils";
import { usePlan } from "../../PlanProvider";

type Props = {
  openEditMode: () => void;
  closeEditMode: () => void;
  isEditMode: boolean;
};

export function DetailsTabBar({
  openEditMode,
  closeEditMode,
  isEditMode,
}: Props) {
  const { plan } = usePlan();

  const { setPlanData, planData } = usePlanWizard();

  const editedPlanActions = planData?.[PlanField.PLAN_SETTINGS]?.planActions;
  const { isOpen, open, close } = useDisclosure();

  const success = () => {
    showSuccess(generateActionSuccessText("Plan")()("updated")());
  };

  const [updatePlan] = useUpdatePlan({
    onSuccess: success,
  });

  const deleteActions = plan?.planActions
    ?.filter(
      (action) => !editedPlanActions?.map((i) => i?.id).includes(action.id)
    )
    .map(({ id }) => id);

  const newActions = editedPlanActions?.filter(
    (editActionItem) =>
      !plan?.planActions?.map((i) => i?.id).includes(editActionItem.id ?? "")
  );

  const updatedActions = editedPlanActions?.map((action, index) => {
    const resourceGroupIds = action?.resourceGroupIds;

    const addPlanAction = newActions?.find((item) => item.id === action?.id);
    const editableAction = plan?.planActions?.find(
      (item) => item.id === action?.id
    );

    const addResourceGroup = resourceGroupIds?.filter(
      (groupId: string) =>
        !editableAction?.resourceGroups
          ?.map((groupItem) => groupItem.id)
          .includes(groupId)
    );

    const deleteResourceGroup = editableAction?.resourceGroups
      ?.map((groupItem) => groupItem.id)
      ?.filter((groupId: string) => !resourceGroupIds?.includes(groupId));

    const inputParam = action?.inputParameters?.map(({ key, value }) => ({
      key,
      value,
    }));

    const editableNotificationGroupIds =
      editableAction?.notificationGroups?.map((groupItem) => groupItem.id);

    const newNotificationGroupIds = action?.notificationGroups?.map(
      (groupItem) => groupItem.id
    );

    const deleteNotificationGroup = editableNotificationGroupIds?.filter(
      (groupId: string) => !newNotificationGroupIds?.includes(groupId)
    );

    const notificationGroups = action?.notificationGroups
      ?.map((group) => {
        const inAdvance = isAdvanceTrigger(group.triggerEvent)
          ? convertToMinutes(
              `${group.inAdvance ?? ""}`,
              group.inAdvanceUnit ?? TimeUnits.minutes
            )
          : 0;
        return (
          group.notificationGroupIds
            ?.filter((item) => item !== undefined)
            ?.map((groupId) => ({
              id: group.id ? group.id : undefined,
              notificationGroupId: groupId,
              scope: group.scope,
              triggerEvent:
                group.triggerEvent ??
                PlanActionNotificationTriggerType.EventActionResourcesError,
              // TODO move maxSignedInt to schema validation
              inAdvance: inAdvance > maxInAdvance ? maxInAdvance : inAdvance,
            })) ?? []
        );
      })
      .flat();

    return {
      action: addPlanAction && {
        actionId: addPlanAction.id ?? "",
        actionType: addPlanAction.actionType ?? PlanActionType.System,
      },
      executionPolicy: action?.executionPolicy,
      id: addPlanAction ? undefined : action?.id,
      name: action.name,
      inputParameters: inputParam,
      notificationGroups: notificationGroups,
      notificationGroupsToDelete: deleteNotificationGroup ?? [],
      order: index + 1,
      resourceGroupIds: addResourceGroup ?? undefined,
      resourceGroupIdsToDelete: deleteResourceGroup ?? undefined,
      runInSequence: action.runInSequence,
      skipWindow: action.skipWindow,
      windowDuration: convertToMinutes(
        action.windowDuration?.toString() ?? "",
        action?.unit ?? TimeUnits.minutes
      ),
    };
  });

  type UpdatedActionsType = typeof updatedActions;
  const changeFirstActionPolicyFromSuccessOrApproval = (
    actions: UpdatedActionsType
  ) => {
    if (actions && actions.length > 0) {
      const firstAction = actions[0];
      if (firstAction?.executionPolicy === ExecutionPolicy.SuccessOrApproval) {
        const modifiedFirstAction = {
          ...firstAction,
          executionPolicy: ExecutionPolicy.Anyway,
        };
        return [modifiedFirstAction, ...actions.slice(1)];
      }
    }
    return actions;
  };

  const handlePlanErrors = () => {
    showError(
      <div>
        {"Please verify plan settings:"}
        {(planData?.[PlanField.ERRORS] ?? []).map((error) => {
          return <div key={error}> {`- ${error}`}</div>;
        })}
      </div>
    );
  };
  const handleSavePlan = () => {
    if (isNotEmpty(planData?.[PlanField.ERRORS] ?? [])) {
      handlePlanErrors();
      return;
    }
    const modifiedFirstAction =
      changeFirstActionPolicyFromSuccessOrApproval(updatedActions);

    updatePlan({
      id: plan?.id ?? "",
      planActions: modifiedFirstAction,
      planActionsToDelete: deleteActions,
    });
    closeEditMode();
  };

  const handleDiscardChanges = () => {
    closeEditMode();
    close();
  };

  const handleSaveChanges = () => {
    handleSavePlan();
    closeEditMode();
    close();
  };

  const handleEditMode = () => {
    const addListId = plan?.planActions?.map((item) => {
      const {
        __typename: IgnoredTypename,
        resourceGroups,
        windowDuration,
        action,
        inputParameters,
        notificationGroups,
        name,
        ...rest
      } = item;
      return {
        windowDuration: windowDuration.toString(),
        inputParameters: inputParameters ?? [],
        ...rest,
        ...action,
        name: name,
        actionName: item.action.name,
        listId: v4(),
        id: item.id ?? "",
        resourceGroups,
        resourceGroupIds: resourceGroups?.map((group) => group.id),
        notificationGroups: notificationGroups?.map((group) => ({
          ...group,
          id: group.id ?? "",
        })),
      };
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: addListId,
      },
    }));
    openEditMode();
  };

  return (
    <>
      <StickyBar css={{ backgroundColor: "transparent" }}>
        <Row>
          <Col>
            <Box boxStyle="lightGrey" mb={theme.spacing.spacing06}>
              <FlexContainer justifyContent="flex-end">
                <If condition={isEditMode}>
                  <Then>
                    <FlexContainer>
                      <Button
                        form="planSettingsForm"
                        icon="save"
                        onClick={handleSavePlan}
                      >
                        Save
                      </Button>
                      <Button
                        severity="low"
                        ml={theme.spacing.spacing04}
                        icon="close"
                        onClick={open}
                      />
                    </FlexContainer>
                  </Then>
                  <Else>
                    <Button icon="edit" onClick={handleEditMode}>
                      Edit Plan
                    </Button>
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </StickyBar>
      <Modal
        onClose={close}
        isOpen={isOpen}
        contentLabel={"Unsaved changes"}
        actions={[
          {
            order: 0,
            onAction: handleDiscardChanges,
            label: "Discard Changes",
            severity: "low",
          },
          {
            order: 1,
            onAction: handleSaveChanges,
            label: "Save",
            severity: "high",
          },
        ]}
      >
        There could be unsaved changes!
      </Modal>
    </>
  );
}
