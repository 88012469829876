/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CronExpression } from "cron-parser";
import dayjs from "dayjs";
import { stringRequired } from "~/components/Forms";
import { dateFormat } from "~/constants";
import { TimeUnits } from "./PlanCreate/components/PlanCreateWizard/types";

const maxPlanActionNameLength = 100;

export const convertToMinutes = (value: string, unit: TimeUnits): number => {
  const toNumber = parseInt(value);
  switch (unit) {
    case TimeUnits.minutes:
      return toNumber;

    case TimeUnits.hours:
      return Math.floor(toNumber * 60);

    case TimeUnits.days:
      return Math.floor(toNumber * 24 * 60);

    case TimeUnits.weeks:
      return Math.floor(toNumber * 7 * 24 * 60);

    default:
      return toNumber;
  }
};

export function getNextDate(interval: CronExpression, offset: number): string {
  return dayjs(interval.next().toString())
    .add(offset, "minute")
    .format(dateFormat.dayMonthYearHour);
}

export function separateConnectedWords(text: string): string {
  const separatedWords = text
    .split("")
    .map((char, index) => {
      if (char === char.toUpperCase() && index > 0) {
        return " " + char;
      }
      return char;
    })
    .join("");

  return separatedWords.trim();
}

export const convertMinutesToHours = (value?: number) => {
  if (!value) {
    return 0;
  }
  return value / 60;
};

export const PlanActionNameSchema = stringRequired("Plan action name")
  .trim()
  .min(1, {
    message: `Plan action name is required`,
  })
  .max(100, {
    message: `Too long name (max: ${maxPlanActionNameLength} characters).`,
  });

export function convertMinutesToReadableTime(minutes: number): string {
  if (minutes < 0) {
    return "Invalid input";
  }

  const oneMinute = 1;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;
  const oneWeek = 7 * oneDay;

  const weeks = Math.floor(minutes / oneWeek);
  const days = Math.floor((minutes % oneWeek) / oneDay);
  const hours = Math.floor((minutes % oneDay) / oneHour);
  const remainingMinutes = minutes % oneHour;

  return (
    (weeks > 0 ? `${weeks} ${pluralize("week", weeks)} ` : "") +
    (days > 0 ? `${days} ${pluralize("day", days)} ` : "") +
    (hours > 0 ? `${hours} ${pluralize("hour", hours)} ` : "") +
    (remainingMinutes > 0
      ? `${remainingMinutes} ${pluralize("minute", remainingMinutes)}`
      : "")
  ).trim();
}

const pluralize = (input: string, count: number) => {
  return `${input}${count > 1 ? "s" : ""}`;
};
