/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useReducer } from "react";
import { FormProvider } from "react-hook-form";
import { When } from "react-if";
import {
  Button,
  FlexContainer,
  SVGIcon,
  Tab,
  Tabs,
  theme,
} from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { WizardButton, WrapWizardButton } from "~/components";
import { useAccountCreate } from "~/views/accounts/AccountCreate/components/hooks/useAccountCreate";
import { useCreateCloudAccount } from "~/views/accounts/hooks/useCreateCloudAccount/useCreateCloudAccount";
import {
  AccountDetailsFormAws,
  AccountDetailsFormAzure,
} from "../AccountDetailsForms";
import { AccountSelectProvider } from "../AccountSelectProvider";
import { FormDataAws, FormDataAzure } from "../formConfig";
import { ConfirmAndFinalize } from "./ConfirmAndFinalize";

type State = {
  currentStep: number;
  subStep: number;
  accountId: string;
};

export function AccountCreateWizard() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    { currentStep: 0, subStep: 0, accountId: "" }
  );

  const nextStep = () => updateState({ currentStep: state.currentStep + 1 });
  const previousStep = () =>
    updateState({ currentStep: state.currentStep - 1 });
  const handleSteps = (key: number) => updateState({ currentStep: key });
  const callback = (id: string) => {
    updateState({ accountId: id, subStep: 1 });
    resetForms();
  };

  const [{ createCloudAccountAws, createCloudAccountAzure }, loading] =
    useCreateCloudAccount({
      onSuccess: callback,
    });

  const {
    resetForms,
    handleSubmitAws,
    handleSubmitAzure,
    provider,
    setProvider,
    methodsAws,
    methodsAzure,
    accountCredentials,
  } = useAccountCreate();

  const onSubmitAws = async (formData: FormDataAws) => {
    await createCloudAccountAws(formData);
  };

  const onSubmitAzure = async (formData: FormDataAzure) => {
    await createCloudAccountAzure(formData);
  };

  const isAws = Provider.Aws === provider;
  const isAzure = Provider.Azure === provider;

  const buttonsStep2 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton form="addAccountForm" type="submit">
        <FlexContainer gap={theme.spacing.spacing02}>
          Next Step
          <SVGIcon name="arrowRight" size="sm" />
        </FlexContainer>
      </WizardButton>
    </WrapWizardButton>
  );

  const onAddNewAccount = () => {
    updateState({ currentStep: 0, subStep: 0, accountId: "" });
  };

  const onEditCredentials = () => {
    updateState({ currentStep: 1 });
  };

  const buttonsStep3 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton
        onClick={
          isAws
            ? handleSubmitAws(onSubmitAws)
            : handleSubmitAzure(onSubmitAzure)
        }
        type="submit"
        initialState={loading ? "loading" : ""}
      >
        Create Cloud Account
      </WizardButton>
    </WrapWizardButton>
  );

  return (
    <Tabs step={state.currentStep} wizard handleTab={handleSteps}>
      <Tab disabled heading="Select Provider">
        <AccountSelectProvider {...{ nextStep, setProvider, provider }} />
      </Tab>
      <Tab disabled heading="Account Details" buttons={buttonsStep2}>
        <When condition={isAws}>
          <FormProvider {...methodsAws}>
            <AccountDetailsFormAws {...{ nextStep }} />
          </FormProvider>
        </When>
        <When condition={isAzure}>
          <FormProvider {...methodsAzure}>
            <AccountDetailsFormAzure {...{ nextStep }} />
          </FormProvider>
        </When>
      </Tab>
      <Tab
        disabled
        heading="Confirm and Finalize"
        buttons={state.subStep === 0 && buttonsStep3}
      >
        <ConfirmAndFinalize
          accountId={state.accountId}
          onAddNewAccount={onAddNewAccount}
          onEditCredentials={onEditCredentials}
          step={state.subStep}
          accountCredentials={accountCredentials}
        />
      </Tab>
    </Tabs>
  );
}
