/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Case, Switch, When } from "react-if";
import { Box, Text, theme } from "@nordcloud/gnui";
import { PlanNotificationTriggerType } from "~/generated/graphql";
import { NoData } from "~/components";
import { useDisclosure } from "~/hooks";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, isEmpty, isNotEmpty } from "~/tools";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import {
  PlanData,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import { PlanGeneralNotificationForm } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanGeneralNotificationForms";
import { PlanWizardCtxProvider } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { TimeUnits } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import {
  convertMinutesToReadableTime,
  convertToMinutes,
} from "~/views/plans/utils";
import { usePlan } from "../../PlanProvider";
import { GeneralNotificationTabBar } from "./GeneralNotificationTabBar";

export function GeneralNotificationsTab() {
  const { open, close, isOpen } = useDisclosure();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { plan } = usePlan();

  const notifications = plan?.notificationGroups ?? [];

  const addListId = notifications.map((item) => {
    return {
      id: item.id,
      triggerEvent: item.triggerEvent,
      notificationGroupIds: item.notificationGroup
        ? [item.notificationGroup.id]
        : [],
      inAdvance: item.inAdvance?.toString() ?? undefined,
      unit: TimeUnits.minutes,
    };
  });

  const [planData, setPlanData] = useState<PlanData>({
    details: undefined,
    schedule_plan: undefined,
    plan_settings: undefined,
    general_notifications: { notificationGroupsGeneral: addListId },
  });

  const editedPlanGeneralNotifications =
    planData?.[PlanField.GENERAL_NOTIFICATIONS]?.notificationGroupsGeneral;

  const success = () => {
    showSuccess(generateActionSuccessText("Plan")()("updated")());
    close();
  };

  const [updatePlan] = useUpdatePlan({
    onSuccess: success,
  });

  const onSubmit = (formData: FormData) => {
    const notificationGroups = formData?.notificationGroupsGeneral
      ?.map((group) => {
        return (
          group.notificationGroupIds
            ?.filter((item) => item !== undefined)
            ?.map((groupId) => ({
              id: isNotEmpty(group.id ?? "") ? group.id : undefined,
              notificationGroupId: groupId,
              triggerEvent:
                group.triggerEvent ?? PlanNotificationTriggerType.EventsStarted,
              inAdvance:
                group.triggerEvent ===
                  PlanNotificationTriggerType.EventsIncoming ?? group.inAdvance
                  ? Number(
                      convertToMinutes(
                        group.inAdvance?.toString() ?? "",
                        group.unit ?? TimeUnits.minutes
                      )
                    )
                  : 0,
            })) ?? []
        );
      })
      .flat();

    const deleteNotificationsGroups = plan?.notificationGroups
      ?.filter(
        (notification) =>
          !editedPlanGeneralNotifications
            ?.map((i) => i?.id)
            .includes(notification.id)
      )
      .map(({ id }) => id);

    updatePlan({
      id: plan?.id ?? "",
      notificationGroups: notificationGroups,
      notificationGroupsToDelete: deleteNotificationsGroups,
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.GENERAL_NOTIFICATIONS]: {
        notificationGroupsGeneral: formData.notificationGroupsGeneral,
      },
    }));
  };

  return (
    <>
      <PlanWizardCtxProvider value={{ planData, setPlanData }}>
        <Box innerSpacing="spacing00">
          <GeneralNotificationTabBar
            openEditMode={open}
            closeEditMode={close}
            isEditMode={isOpen}
            {...{ hasUnsavedChanges }}
          />
          <Switch>
            <Case condition={isOpen}>
              <PlanGeneralNotificationForm
                {...{ setHasUnsavedChanges }}
                onSubmit={onSubmit}
              />
            </Case>
            <Case condition={!isOpen && isNotEmpty(notifications)}>
              <Row>
                <Col xs={8} sm={8} md={12} lg={12}>
                  {notifications?.map((item, index) => {
                    return (
                      <Box
                        boxStyle="lightGrey"
                        key={item.id}
                        mb={theme.spacing.spacing03}
                      >
                        <Text
                          size="sm"
                          tag="div"
                          color={theme.color.text.text02}
                        >
                          Notification #{index + 1}
                        </Text>
                        <Text tag="div">
                          <>
                            Send to{" "}
                            <Text tag="span" color={theme.color.text.info}>
                              {item.notificationGroup?.name}{" "}
                            </Text>
                            when{" "}
                            <Text tag="span" weight="medium">
                              {item.triggerEvent
                                ?.replace(/_/g, " ")
                                .toLowerCase()}
                            </Text>
                            <When
                              condition={
                                item.triggerEvent ===
                                PlanNotificationTriggerType.EventsIncoming
                              }
                            >
                              {" "}
                              in{" "}
                              <Text tag="span" weight="medium">
                                {convertMinutesToReadableTime(
                                  Number(item?.inAdvance)
                                )}
                              </Text>
                            </When>
                          </>
                        </Text>
                      </Box>
                    );
                  })}
                </Col>
              </Row>
            </Case>
            <Case condition={!isOpen && isEmpty(notifications)}>
              <NoData hasIcon message="There are no General Notifications" />
            </Case>
          </Switch>
        </Box>
      </PlanWizardCtxProvider>
    </>
  );
}
