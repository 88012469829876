/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useReducer, useState } from "react";
import { Else, If, Then } from "react-if";
import { Box, Button, FlexContainer, theme } from "@nordcloud/gnui";
import { Resource } from "~/generated/graphql";
import { useComponentsSelector } from "~/hooks";
import { mapResourceToSidebarOption } from "~/hooks/utils";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty } from "~/tools";
import { Item, ColumnType, SelectionMethod } from "~/types";
import { ResourceDragDrop } from "~/views/resourceGroups/components/Resource/components";
import { ResourceSidebar } from "~/views/resourceGroups/components/Resource/components/ResourceSidebar";
import { ResourceExpression } from "~/views/resourceGroups/components/Resource/ResourceExpression";
import { useUpdateResourceGroup } from "~/views/resourceGroups/hooks";
import { useResourceGroup } from "../../ResourceGroupProvider";
import { ResourcesTable } from "./components/ResourcesTable";

type State = {
  isEditMode: boolean;
  deletedItems: string[];
  expression: string;
  dynamicResources: Item[];
};

export function ResourcesTab() {
  const [selectedPendingResources, setSelectedPendingResources] = useState<
    Resource[]
  >([]);

  const [deletedPendingResources, setDeletedPendingResources] = useState<
    string[]
  >([]);

  const { resourceGroup: resourceGroupData } = useResourceGroup();

  const resourceGroup = {
    id: resourceGroupData?.id,
    resourceSelectors: resourceGroupData?.resourceSelectors?.filter(
      (resource) => !resource.tagsExpression
    ),
  };
  const expressionSelector = resourceGroupData?.resourceSelectors?.find(
    (resource) => resource.tagsExpression
  );

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    {
      isEditMode: false,
      deletedItems: [""],
      expression: expressionSelector?.tagsExpression ?? "",
      dynamicResources: [
        {
          id: "",
          name: undefined,
          columns: [
            {
              data: [SelectionMethod.manual],
              type: ColumnType.selectionMethod,
            },
          ],
          selected: undefined,
        },
      ],
    }
  );

  const prepareResources = resourceGroup?.resourceSelectors?.map(
    ({ resource }) => resource?.id ?? ""
  ) ?? [""];

  const prepareSelectedPendingResources =
    selectedPendingResources?.map((resource) => resource.id) ?? [];

  const filterDeletedPendingResources = (
    selectedResources: Item[],
    deletedPendingResourcesIDs: string[]
  ): Item[] =>
    selectedResources.filter(
      (resource) => !deletedPendingResourcesIDs.includes(resource.id)
    );

  const selectors = useMemo(() => {
    const selectedItems =
      resourceGroup?.resourceSelectors?.map(({ resource }) => {
        return {
          name: resource?.name ?? "",
          id: resource?.id ?? "",
          columns: [
            {
              data: [resource?.os?.subType ?? "", resource?.os?.type ?? ""],
              type: ColumnType.os,
            },
            {
              data: [resource?.name ?? "", resource?.id ?? ""],
              type: ColumnType.text,
            },
            {
              data: [SelectionMethod.manual],
              type: ColumnType.selectionMethod,
            },
          ],
        };
      }) ?? [];

    const selectedPendingItems =
      selectedPendingResources?.map((resource) => {
        return {
          id: resource.id,
          name: resource.name,
          columns: [
            {
              data: [resource?.os?.subType ?? "", resource?.os?.type ?? ""],
              type: ColumnType.os,
            },
            {
              data: [resource?.name ?? "", resource?.id ?? ""],
              type: ColumnType.text,
            },
            {
              data: [SelectionMethod.manual],
              type: ColumnType.selectionMethod,
            },
          ],
        };
      }) ?? [];

    const selectedResources = [...selectedItems, ...selectedPendingItems];

    return filterDeletedPendingResources(
      selectedResources,
      deletedPendingResources
    );
  }, [
    resourceGroup?.resourceSelectors,
    selectedPendingResources,
    deletedPendingResources,
  ]);

  const dynamicResources =
    resourceGroupData?.resourceSelectors?.find(
      (resource) => resource.dynamicResources
    )?.dynamicResources ?? [];

  const manualResources =
    resourceGroup?.resourceSelectors?.map(({ resource }) => resource) ?? [];

  const resourceTableItems = [
    ...manualResources,
    ...dynamicResources,
    ...selectedPendingResources,
  ];

  const [selectedOptions, setSelectedOptions] = useState([
    ...prepareResources,
    ...prepareSelectedPendingResources,
  ]);

  const [selectedResources, setSelectedResources] = useState([
    ...prepareResources,
    ...prepareSelectedPendingResources,
  ]);
  const [allResources, setAllResources] = useState<Resource[]>([]);

  const handleSidebarSubmit = (ids: string[]) => {
    const deleteIds = selectedResources
      .filter((item) => !ids.includes(item))
      .concat(state.deletedItems);

    updateState({ deletedItems: deleteIds });
    setSelectedOptions(ids);
    setSelectedResources(ids);

    const data = allResources
      .map(mapResourceToSidebarOption)
      .filter((item) => ids.includes(item.id));
    const resources = [...data, ...state.dynamicResources].filter((r) =>
      isNotEmpty(r.id)
    );
    handleShowSearch(resources);
  };

  const {
    items,
    handleShowItems,
    handleDelete,
    handleShowSearch,
    handleDragEnd,
  } = useComponentsSelector(selectors);

  const handleRemoveItem = (item: Item) => {
    setSelectedOptions((selectedItems) =>
      selectedItems.filter((option) => option !== item.id)
    );

    setSelectedResources((selectedItems) =>
      selectedItems.filter((option) => option !== item.id)
    );

    setSelectedPendingResources((selectedItems) =>
      selectedItems.filter((option) => option.id !== item.id)
    );

    setDeletedPendingResources((selectedItems) => [...selectedItems, item.id]);

    handleDelete(item);

    updateState({
      deletedItems: [...state.deletedItems, item.id],
    });
  };

  const [update, loading] = useUpdateResourceGroup({
    onSuccess: () => {
      updateState({ isEditMode: false, deletedItems: [""] });
      showSuccess(generateActionSuccessText("Resource Group")()("updated")());
    },
  });

  const handleEdit = () => {
    updateState({ isEditMode: true });
    handleShowItems();
  };

  const handleDynamicUpdate = (dynamicItems: Item[]) => {
    handleShowSearch([...selectors, ...dynamicItems]);
    updateState({ dynamicResources: dynamicItems });
  };

  const handleReorder = (ids: string[]) => {
    setSelectedResources(ids);
  };
  const { expression, setExpression } = useResourceGroup();

  const handleSave = () => {
    const deletedResources = resourceGroup?.resourceSelectors
      ?.map((item) => ({
        id: item?.id ?? "",
        resourceId: item.resource?.id ?? "",
      }))
      ?.filter((item) => state.deletedItems.includes(item.resourceId))
      .map(({ id }) => id) ?? [""];

    const selectorIds = selectedResources?.map((item) => {
      const existed = resourceGroup?.resourceSelectors?.find(
        (i) => i.resource?.id === item
      );
      return {
        id: existed?.id ?? null,
        resourceId: existed?.resource?.id ?? item,
      };
    });

    const addOrder = selectorIds?.map((resourceId, i) => {
      return {
        resourceId: resourceId?.resourceId,
        order: i + 1,
        id: resourceId?.id ?? null,
      };
    });

    const prepareExpression = [
      {
        order: 1,
        tagsExpression: state.expression,
        resourceId: null,
      },
    ];

    const resourceSelectors = isNotEmpty(expression ?? [])
      ? [...addOrder, ...prepareExpression]
      : addOrder;

    const resourceSelectorsToDelete = [
      ...deletedResources,
      expressionSelector?.id ?? "",
    ].filter((i) => i);

    update({
      id: resourceGroup?.id ?? "",
      resourceSelectors: resourceSelectors,
      resourceSelectorsToDelete: resourceSelectorsToDelete,
    });

    setSelectedPendingResources([]);
    setDeletedPendingResources([]);
  };

  useEffect(() => {
    const ids = items.SELECTED_ITEMS.map((item) => item.id).filter(
      (id) => !id.match(/dynamic/g)
    );
    setSelectedResources(ids);
  }, [items.SELECTED_ITEMS, setSelectedResources]);

  const handleWizardResourceDynamicSubmit = (formData: {
    expression: string;
  }) => {
    if (isNotEmpty(formData.expression)) {
      updateState({ expression: formData.expression });
    } else {
      updateState({ expression: "" });
    }
  };

  const handleCancelEdit = () => {
    setSelectedOptions(prepareResources);
    updateState({ isEditMode: false });
  };

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer>
          <If condition={state.isEditMode}>
            <Then>
              <ResourceSidebar
                handleManualSubmit={handleSidebarSubmit}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setAllResources={setAllResources}
                setSelectedPendingResources={setSelectedPendingResources}
              />
              <ResourceExpression
                defaultValue={expressionSelector?.tagsExpression ?? undefined}
                handleDynamicUpdate={handleDynamicUpdate}
                onSubmit={handleWizardResourceDynamicSubmit}
                {...{ setExpression }}
              />
              <Button
                ml={theme.spacing.spacing04}
                mr={theme.spacing.spacing04}
                onClick={handleSave}
                disabled={loading}
              >
                Save
              </Button>
              <Button severity="low" icon="close" onClick={handleCancelEdit} />
            </Then>
            <Else>
              <Button ml="auto" onClick={handleEdit}>
                Edit Resources
              </Button>
            </Else>
          </If>
        </FlexContainer>
      </Box>
      <If condition={state.isEditMode}>
        <Then>
          <ResourceDragDrop
            {...{
              items,
              handleDragEnd,
              handleRemoveItem,
              handleReorder,
            }}
          />
        </Then>
        <Else>
          <ResourcesTable resources={resourceTableItems} />
        </Else>
      </If>
    </>
  );
}
