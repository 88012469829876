/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { parseExpression, ParserOptions } from "cron-parser";
import { v4 } from "uuid";
import { ExtendedTooltip, Text, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components";
import { getNextDate } from "~/views/plans/utils";

type Props = {
  cronExpression?: string;
  timezone?: string;
  offset: number;
};

export function NextDateCron({ cronExpression, timezone, offset }: Props) {
  const parseOptions = timezone ? { tz: timezone } : undefined;

  const generateInterval = () =>
    cronExpression &&
    isValidCronExpression(cronExpression) &&
    parseExpression(cronExpression, parseOptions);

  const tooltipInterval = generateInterval();
  const nextInterval = generateInterval();

  const nextDates =
    tooltipInterval &&
    Array(3)
      .fill(0)
      .map(() => getNextDate(tooltipInterval, offset));

  const firstInterval = `Next: ${nextDates && nextDates?.[0]}`;

  if (nextInterval) {
    return (
      <Text data-testid="next-date-cron" tag="span" size="sm">
        <Tooltip tooltip={nextDates || [""]} description={firstInterval} />
      </Text>
    );
  }
  return null;
}

type TooltipProps = {
  tooltip: string[];
  description: string;
};

function Tooltip({ tooltip, description }: TooltipProps) {
  const displayTooltip = tooltip?.map((item: string) => {
    return (
      <Text key={v4()} size="sm" mb={0} color="text01">
        Next: {item}
      </Text>
    );
  });

  return (
    <ExtendedTooltip
      caption={<>{displayTooltip}</>}
      hideTimeout={0}
      zIndex={theme.zindex.topoftheworld}
    >
      <TooltipText
        size="sm"
        tag="span"
        mr={theme.spacing.spacing01}
        weight="regular"
        color={theme.color.text.text02}
        css={{
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          textDecorationThickness: "1px",
          textDecorationColor: theme.color.text.text02,
          textUnderlineOffset: theme.spacing.spacing01,
        }}
      >
        {description}
      </TooltipText>
    </ExtendedTooltip>
  );
}

export const isValidCronExpression = (
  cronExpression: string,
  parseOptions?: ParserOptions
) => {
  if (cronExpression.length === 0) {
    return false;
  }
  try {
    parseExpression(cronExpression, parseOptions);
  } catch (e) {
    return false;
  }

  return true;
};
