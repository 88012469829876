/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { useStepState } from "../StepProvider";
import { ActionView } from "./ActionView";
import { AddAction } from "./AddAction";
import { DraggableActions } from "./DraggableActions";

type Props = {
  nextStep?: () => void;
};

export function ActionsView({ nextStep }: Props) {
  const { stepState } = useStepState();
  const prepareActions = stepState.actions?.map((action) => ({
    ...action,
    id: action.id ?? "",
    listId: action.listId ?? "",
    name: action.name,
    description: "",
    color: action.color ?? "",
    actionType: action.actionType,
  }));

  return (
    <Row>
      <Col xs={4} sm={8} md={3} lg={3}>
        <FlexContainer direction="column" justifyContent="flex-start">
          <Text width="100%" align="left">
            Actions
          </Text>
          <DraggableActions actions={prepareActions} nextStep={nextStep} />
          <AddAction buttonName="Add Action" />
        </FlexContainer>
      </Col>
      <Col xs={4} sm={8} md={5} lg={9}>
        <Box boxStyle="grey" innerSpacing="spacing04">
          <FlexContainer alignItems="stretch" gap={theme.spacing.spacing04}>
            <ActionView {...{ nextStep }} />
          </FlexContainer>
        </Box>
      </Col>
    </Row>
  );
}
