/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExtendedTooltip, FlexContainer, SVGIcon } from "@nordcloud/gnui";
import { getActionStatus, getEventStatus } from "../helpers";

type Props = {
  status?: string;
  actionLength?: number;
  type: "Event" | "Action";
  size?: "md" | "lg";
};

export function TooltipIconStatus({
  status,
  type,
  actionLength,
  size = "md",
}: Props) {
  const color =
    type === "Event"
      ? getEventStatus(status, actionLength).color
      : getActionStatus(status).color;

  const icon =
    type === "Event"
      ? getEventStatus(status, actionLength).icon
      : getActionStatus(status).icon;

  const description =
    type === "Event"
      ? getEventStatus(status, actionLength).description
      : getActionStatus(status).description;

  const tooltipColor =
    type === "Event"
      ? getEventStatus(status).tooltipColor
      : getActionStatus(status).tooltipColor;

  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <ExtendedTooltip
        status={tooltipColor}
        caption={description}
        hideTimeout={0}
        position="start"
      >
        <FlexContainer justifyContent="center" alignItems="center">
          <SVGIcon size={size} name={icon} color={color()} />
        </FlexContainer>
      </ExtendedTooltip>
    </FlexContainer>
  );
}
