/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Buffer } from "buffer";
import { Col, Row } from "react-awesome-styled-grid";
import { FormProvider, useFormContext } from "react-hook-form";
import { Heading, Input, Label, Text, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { FormGroup, TransformableInput, StepContainer } from "~/components";
import { useCustomer } from "~/context";
import { showError } from "~/services/toast";
import { useGetAwsOnboardingTemplate } from "~/views/accounts/hooks/useAwsOnboardingTemplate/useAwsOnboardingTemplate";
import { FormField } from "../formConfig";
import {
  AWS_DOWNLOAD_TEMPLATE_ERR,
  AWS_INFO_EXTERNAL_ID,
  AWS_INFO_ONBOARDING,
  URL_AWS_CONSOLE,
  URL_AWS_EXTERNAL_IDS,
} from "./constants";
import { trimTransform } from "./utils";

type Props = {
  nextStep: () => void;
};

export function AccountDetailsFormAws({ nextStep }: Props) {
  const formMethods = useFormContext();
  const { customer } = useCustomer();

  const {
    register,
    formState: { errors, isValid },
    trigger,
    handleSubmit,
    control,
  } = formMethods;

  const submit = () => {
    trigger();
    if (isValid) {
      nextStep();
    }
  };

  const useAwsTemplate = () => {
    const { awsOnboardingTemplate, error } = useGetAwsOnboardingTemplate();
    if (error) {
      return { onClick: () => showError(AWS_DOWNLOAD_TEMPLATE_ERR) };
    }

    return {
      href: `data:text/plain;base64,${Buffer.from(
        awsOnboardingTemplate || "",
        "utf-8"
      ).toString("base64")}`,
    };
  };

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Row>
          <Col>
            <Heading level={3} marginBottom="spacing05">
              Account details
            </Heading>
            <input
              type="hidden"
              id="provider"
              {...register(FormField.provider)}
              value={Provider.Aws}
            />
            <FormGroup error={errors[FormField.displayName]}>
              <Label name="Display Name" />
              <Input id="displayName" {...register(FormField.displayName)} />
            </FormGroup>
            <FormGroup error={errors[FormField.description]}>
              <Label name="Description" htmlFor="description" />
              <Input id="description" {...register(FormField.description)} />
            </FormGroup>
            <FormGroup error={errors[FormField.environment]}>
              <Label name="Environment" htmlFor="environment" />
              <Input id="environment" {...register(FormField.environment)} />
            </FormGroup>

            <Heading level={3} marginBottom="spacing05">
              Account Credentials
            </Heading>
            <Text color={theme.color.text.text02}>{AWS_INFO_ONBOARDING}</Text>
            <FormProvider {...formMethods}>
              <form id="addAccountForm" onSubmit={handleSubmit(submit)}>
                <StepContainer counter={1}>
                  <Text>
                    Sign in to the{" "}
                    <a href={URL_AWS_CONSOLE} target="_blank" rel="noreferrer">
                      AWS Management Console
                    </a>
                  </Text>
                  <FormGroup error={errors[FormField.providerId]}>
                    <Label
                      htmlFor={FormField.providerId}
                      name="Provide Account ID"
                      required
                    />
                    <TransformableInput
                      name={FormField.providerId}
                      transform={trimTransform}
                      control={control}
                      placeholder="e.g. 123456789012"
                    />
                  </FormGroup>
                </StepContainer>

                <StepContainer counter={2}>
                  <Text marginBottom={theme.spacing.spacing01}>
                    In AWS Management Console run CloudFormation{" "}
                  </Text>
                </StepContainer>

                <StepContainer counter={3}>
                  <Text marginBottom={theme.spacing.spacing01}>
                    Create a CloudFormation Stack based on{" "}
                    <a
                      download="fleetControlOnboardingAws.yaml"
                      {...useAwsTemplate()}
                    >
                      CloudFormation Template
                    </a>
                  </Text>
                  <Text size="sm" color={theme.color.text.text02}>
                    {AWS_INFO_EXTERNAL_ID}
                  </Text>

                  <FormGroup error={errors[FormField.roleExternalId]}>
                    <Label
                      htmlFor={FormField.roleExternalId}
                      name="Provide Role External ID"
                    />
                    <TransformableInput
                      name={FormField.roleExternalId}
                      transform={trimTransform}
                      control={control}
                      placeholder="Provide Role External ID"
                      defaultValue={customer.id}
                      disabled
                    />
                  </FormGroup>
                  <a
                    href={URL_AWS_EXTERNAL_IDS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    More information about External IDs
                  </a>
                </StepContainer>

                <StepContainer counter={4}>
                  <Text marginBottom={theme.spacing.spacing05}>
                    In AWS Management Console run IAM and find role by name
                    (NordcloudFleetControlServiceRole)
                  </Text>
                  <Label
                    htmlFor={FormField.roleArn}
                    name="Provide Role ARN"
                    required
                  />
                  <FormGroup error={errors[FormField.roleArn]}>
                    <TransformableInput
                      name={FormField.roleArn}
                      data-testid={FormField.roleArn}
                      transform={trimTransform}
                      control={control}
                      placeholder="e.g. arn:aws:iam::123456789012:role/NordcloudFleetControlServiceRole"
                    />
                  </FormGroup>
                </StepContainer>
              </form>
            </FormProvider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
