/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Provider } from "~/generated/graphql";
import { SvgName } from "~/tools/types";

export function getProviderIcon(provider: Provider | string): SvgName {
  switch (provider) {
    case "AWS":
      return "aws";
    case "GC":
      return "gcp";
    case "AZURE":
      return "azure";
    default:
      return "aws";
  }
}
