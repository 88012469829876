/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { FlexContainer, theme } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { PROVIDER_CONFIG } from "../formConfig";
import { IconBoxSelector } from "./IconBoxSelector";

type Props = {
  nextStep: () => void;
  setProvider: (provider: Provider) => void;
  provider: Provider | null;
};

export function AccountSelectProvider({
  nextStep,
  setProvider,
  provider,
}: Props) {
  function handleSelect(selectedProvider: Provider) {
    setProvider(selectedProvider);
    nextStep();
  }

  return (
    <FlexContainer justifyContent="center" gap={theme.spacing.spacing05}>
      <IconBoxSelector
        items={PROVIDER_CONFIG}
        selected={provider}
        onSelect={(selectedProvider) => handleSelect(selectedProvider)}
      />
    </FlexContainer>
  );
}
