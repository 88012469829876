/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { If, Then, Else } from "react-if";
import { SVGIcon, theme, ExtendedTooltip } from "@nordcloud/gnui";
import { ChangeDetails, Channel } from "~/generated/graphql";
import { NameWithIdCell } from "~/components/Tables/cells/NameWithIdCell";
import {
  FlexColumn,
  FlexColumnSmall,
  MediumIconColumn,
} from "~/components/Tables/styles";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { inflect } from "~/tools";
import { Actions } from "./Actions";
import {
  filterWebhookChannelByType,
  getEmails,
  Channels,
  ChannelsTypes,
} from "./helpers";

export type NotificationGroups = {
  id: string;
  name: string;
  changeDetails: ChangeDetails;
  channels: Channel[];
};

const columnHelper = createColumnHelper<NotificationGroups>();

export function columns() {
  return [
    columnHelper.accessor((row) => row?.name, {
      header: "Notification Group Name / ID",
      cell: ({ row }) => (
        <NameWithIdCell
          id={row.original.id}
          name={row.original.name}
          link={ROUTES.notificationGroups.generateDetails(row.original.id)}
        />
      ),
      meta: FlexColumn,
    }),
    columnHelper.accessor((row) => row?.changeDetails.createdAt, {
      header: "Created",
      cell: (cell) => dayjs(cell.getValue()).format(dateFormat.dayMonthYear),
      meta: FlexColumnSmall,
    }),
    columnHelper.accessor((row) => row?.changeDetails.updatedAt, {
      header: "Updated",
      cell: (cell) => dayjs(cell.getValue()).format(dateFormat.dayMonthYear),
      meta: FlexColumnSmall,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "Notifications",
      cell: ({ row }) => {
        const uniqueChannels = filterUniqueTypeNames(row.original.channels);
        const { webhookChannel: slackChannels } = filterWebhookChannelByType(
          row.original.channels,
          Channels.SLACK
        );
        const { webhookChannel: pagerDutyChannels } =
          filterWebhookChannelByType(
            row.original.channels,
            Channels.PAGER_DUTY
          );
        const emailsList = getEmails(row.original.channels);

        return (
          <div>
            <>
              <If condition={uniqueChannels.includes(Channels.SLACK)}>
                <Then>
                  <ExtendedTooltip
                    caption={
                      <div>{`${slackChannels.length} ${inflect("Channel")(
                        slackChannels.length
                      )}`}</div>
                    }
                    hideTimeout={0}
                  >
                    <SVGIcon name="slackColorful" size="md" />
                  </ExtendedTooltip>
                </Then>
                <Else>
                  <SVGIcon
                    name="slack"
                    size="md"
                    color={theme.color.text.text03}
                  />
                </Else>
              </If>
              <If condition={uniqueChannels.includes(Channels.PAGER_DUTY)}>
                <Then>
                  <ExtendedTooltip
                    caption={
                      <div>{`${pagerDutyChannels.length} Routing ${inflect(
                        "Key"
                      )(pagerDutyChannels.length)}`}</div>
                    }
                    hideTimeout={0}
                  >
                    <SVGIcon name="pagerDutyColorful" size="md" />
                  </ExtendedTooltip>
                </Then>
                <Else>
                  <SVGIcon
                    name="pagerDuty"
                    size="md"
                    color={theme.color.text.text03}
                  />
                </Else>
              </If>
              <If condition={uniqueChannels.includes(ChannelsTypes.EMAIL)}>
                <Then>
                  <ExtendedTooltip
                    caption={
                      <If condition={emailsList.length <= 5}>
                        <Then>
                          {emailsList.map((email) => (
                            <div key={email}>{email}</div>
                          ))}
                        </Then>
                        <Else>{emailsList.length} emails</Else>
                      </If>
                    }
                    hideTimeout={0}
                  >
                    <SVGIcon name="mail" size="md" />
                  </ExtendedTooltip>
                </Then>
                <Else>
                  <SVGIcon
                    name="mail"
                    size="md"
                    color={theme.color.text.text03}
                  />
                </Else>
              </If>
            </>
          </div>
        );
      },
      meta: FlexColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        return (
          <Actions
            {...{
              notificationGroupId: `${row.original?.id}`,
              notificationGroupName: `${row.original?.name}`,
            }}
          />
        );
      },
      meta: MediumIconColumn,
    }),
  ] as never;
}

function filterUniqueTypeNames(data: Channel[]): string[] {
  const uniqueTypeNames: Set<string> = new Set();

  data.forEach((channel) => {
    if (channel?.typeProperties?.__typename === ChannelsTypes.EMAIL) {
      uniqueTypeNames.add(channel?.typeProperties?.__typename);
    }
    if (channel?.typeProperties?.__typename === ChannelsTypes.WEBHOOK) {
      uniqueTypeNames.add(channel?.typeProperties?.type);
    }
  });

  return Array.from(uniqueTypeNames);
}
