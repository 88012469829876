/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  theme,
  FlexContainer,
  Label,
  Dropdown,
  Input,
  Text,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { PlanData, PlanField } from "../constants";
import { units } from "../PlanSettingsForms/components/ActionSettingsForm/options";
import { FormField } from "../types";
import { NextDateCron } from "./NextDateCron";
import { convertToMinutes } from "./utils";

type Props = {
  planData?: PlanData;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function ScheduleCron({ planData, setPlanData }: Props) {
  const formMethods = useFormContext();

  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;

  const handleChange = (
    name: FormField,
    onChange: (value: string | number) => void,
    value: string | number
  ) => {
    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [name]: value,
      },
    }));

    onChange(value);
  };

  const timezone =
    watch(FormField.SCHEDULE_TIMEZONE) ??
    planData?.plan_settings?.scheduleTimezone;

  const cronExpression =
    watch(FormField.SCHEDULE_CRON) ?? planData?.plan_settings?.scheduleCron;
  const scheduleOffset =
    watch(FormField.SCHEDULE_OFFSET) ?? planData?.plan_settings?.scheduleOffset;
  const scheduleOffsetUnit =
    watch(FormField.SCHEDULE_OFFSET_UNIT) ??
    planData?.plan_settings?.scheduleOffsetUnit;

  return (
    <>
      <FormGroup error={errors[FormField.SCHEDULE_CRON]}>
        <Label name="Cron Window Start" htmlFor="scheduleCron" required />
        <FlexContainer
          mb={theme.spacing.spacing04}
          gap={theme.spacing.spacing03}
        >
          <div css={{ width: "12rem" }}>
            <Controller
              defaultValue={planData?.plan_settings?.scheduleCron}
              control={control}
              name={FormField.SCHEDULE_CRON}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    value={field.value}
                    name={FormField.SCHEDULE_CRON}
                    id="scheduleCron"
                    onChange={(e) =>
                      handleChange(
                        FormField.SCHEDULE_CRON,
                        () => field.onChange(e.target.value),
                        e.target.value
                      )
                    }
                  />
                );
              }}
            />
          </div>
        </FlexContainer>
        <NextDateCron
          cronExpression={cronExpression}
          timezone={timezone}
          offset={convertToMinutes(
            parseInt(scheduleOffset, 10),
            scheduleOffsetUnit
          )}
        />
      </FormGroup>
      <FormGroup error={errors[FormField.SCHEDULE_OFFSET]}>
        <Label css={{ marginBottom: 0 }} name="Delay" />
        <Text
          mb={theme.spacing.spacing02}
          size="sm"
          color={theme.color.text.text02}
        >
          Choose a time to delay the plan execution after the scheduled cron.
        </Text>
        <FlexContainer
          mb={theme.spacing.spacing04}
          gap={theme.spacing.spacing03}
        >
          <div css={{ width: "5rem" }}>
            <Controller
              defaultValue={planData?.plan_settings?.scheduleOffset}
              control={control}
              name={FormField.SCHEDULE_OFFSET}
              render={({ field }) => {
                return (
                  <Input
                    {...field}
                    value={field.value}
                    name={FormField.SCHEDULE_OFFSET}
                    id="scheduleOffset"
                    onChange={(e) =>
                      handleChange(
                        FormField.SCHEDULE_OFFSET,
                        () => field.onChange(e.target.value),
                        e.target.value
                      )
                    }
                  />
                );
              }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name={FormField.SCHEDULE_OFFSET_UNIT}
              render={({ field: { onChange, value } }) => {
                return (
                  <Dropdown
                    name="Select Delay Unit"
                    options={units}
                    onChange={(val) =>
                      handleChange(
                        FormField.SCHEDULE_OFFSET_UNIT,
                        onChange,
                        val
                      )
                    }
                    value={value}
                    minNumOfOptionsToShowSearchBox={10}
                  />
                );
              }}
            />
          </div>
        </FlexContainer>
      </FormGroup>
    </>
  );
}
