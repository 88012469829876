/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  PlanActionNotificationGroupScope,
  PlanActionNotificationTriggerType,
} from "~/generated/graphql";
import { capitalize } from "~/tools";

export const optionsScope = Object.values(PlanActionNotificationGroupScope).map(
  (scope) => ({
    value: scope,
    label: capitalize(scope),
  })
);

export const optionsTriggerResource = Object.values(
  PlanActionNotificationTriggerType
)
  .filter((item) => item.match(/resources/gi))
  .map((trigger) => ({
    value: trigger,
    label: getLabelText(trigger),
  }));

export const optionsTriggerAction = Object.values(
  PlanActionNotificationTriggerType
)
  .filter((item) => !item.match(/resources/gi))
  .map((trigger) => ({
    value: trigger,
    label: getLabelText(trigger),
  }));

export function getLabelText(str: PlanActionNotificationTriggerType): string {
  const result: string = (() => {
    switch (str) {
      case PlanActionNotificationTriggerType.EventActionResourcesFailed:
      case PlanActionNotificationTriggerType.EventActionsFailed:
        return "fails";
      case PlanActionNotificationTriggerType.EventActionResourcesFinished:
      case PlanActionNotificationTriggerType.EventActionsFinished:
        return "finishes";
      case PlanActionNotificationTriggerType.EventActionResourcesStarted:
      case PlanActionNotificationTriggerType.EventActionsStarted:
        return "starts";
      case PlanActionNotificationTriggerType.EventActionsIncoming:
        return "is upcoming";
      case PlanActionNotificationTriggerType.EventActionsNotApproved:
        return "is not approved";
      case PlanActionNotificationTriggerType.EventActionResourcesNotInTimeWindow:
        return "not in time window";
      case PlanActionNotificationTriggerType.EventActionResourcesTimeout:
        return "timeout";
      case PlanActionNotificationTriggerType.EventActionResourcesError:
        return "error";
      default:
        return "";
    }
  })();
  return capitalize(result);
}
