/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Buffer } from "buffer";
import { Col, Row } from "react-awesome-styled-grid";
import { FormProvider, useFormContext } from "react-hook-form";
import { Heading, Input, Label, Text, theme, Spacer } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";
import { FormGroup, TransformableInput, StepContainer } from "~/components";
import { showError } from "~/services/toast";
import { useGetAzureOnboardingScript } from "~/views/accounts/hooks/useAzureOnboardingScript/useAzureOnboardingScript";
import { FormField } from "../formConfig";
import {
  AZURE_DOWNLOAD_SCRIPT_ERR,
  AZURE_INFO_ONBOARDING,
  URL_AZURE_CONSOLE,
  AZURE_SUB_PLACEHOLDER,
} from "./constants";
import { trimTransform } from "./utils";

type Props = {
  nextStep: () => void;
};

export function AccountDetailsFormAzure({ nextStep }: Props) {
  const formMethods = useFormContext();

  const {
    register,
    formState: { errors, isValid },
    trigger,
    handleSubmit,
    control,
  } = formMethods;

  const submit = () => {
    trigger();
    if (isValid) {
      nextStep();
    }
  };

  const useAzureScript = () => {
    const { azureOnboardingScript, error } = useGetAzureOnboardingScript(
      AZURE_SUB_PLACEHOLDER
    );
    if (error) {
      return { onClick: () => showError(AZURE_DOWNLOAD_SCRIPT_ERR) };
    }

    const script = azureOnboardingScript?.replaceAll(
      AZURE_SUB_PLACEHOLDER,
      formMethods.getValues()[FormField.subscriptionId]
    );
    return {
      href: `data:text/plain;base64,${Buffer.from(
        script || "",
        "utf-8"
      ).toString("base64")}`,
    };
  };

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Row>
          <Col>
            <Heading level={3} marginBottom="spacing05">
              Account details
            </Heading>
            <input
              type="hidden"
              id="provider"
              {...register(FormField.provider)}
              value={Provider.Azure}
            />
            <FormGroup error={errors[FormField.displayName]}>
              <Label name="Display Name" />
              <Input id="displayName" {...register(FormField.displayName)} />
            </FormGroup>
            <FormGroup error={errors[FormField.description]}>
              <Label name="Description" htmlFor="description" />
              <Input id="description" {...register(FormField.description)} />
            </FormGroup>
            <FormGroup error={errors[FormField.environment]}>
              <Label name="Environment" htmlFor="environment" />
              <Input id="environment" {...register(FormField.environment)} />
            </FormGroup>

            <Heading level={3} marginBottom="spacing05">
              Account Credentials
            </Heading>
            <Text color={theme.color.text.text02}>{AZURE_INFO_ONBOARDING}</Text>
            <FormProvider {...formMethods}>
              <form id="addAccountForm" onSubmit={handleSubmit(submit)}>
                <StepContainer counter={1}>
                  <Text>
                    Sign in to the{" "}
                    <a
                      href={URL_AZURE_CONSOLE}
                      target="_blank"
                      rel="noreferrer"
                    >
                      AZURE
                    </a>
                  </Text>
                  <FormGroup error={errors[FormField.subscriptionId]}>
                    <Label
                      htmlFor={FormField.subscriptionId}
                      name="Provide Subscription ID"
                      required
                    />
                    <TransformableInput
                      name={FormField.subscriptionId}
                      transform={trimTransform}
                      control={control}
                      placeholder={`e.g. ${AZURE_SUB_PLACEHOLDER}`}
                    />
                  </FormGroup>
                </StepContainer>

                <StepContainer counter={2}>
                  <Text marginBottom={theme.spacing.spacing01}>
                    Open Azure Cloud Shell and execute the{" "}
                    <a
                      download="fleetControlOnboardingAzure.sh"
                      {...useAzureScript()}
                    >
                      Azure onboarding script.
                    </a>
                  </Text>
                  <Text size="sm">
                    It creates Azure Service Principal and the custom role
                    definition with all necessary permissions.
                  </Text>
                </StepContainer>

                <StepContainer counter={3}>
                  <Text marginBottom={theme.spacing.spacing01}>
                    The output will include credentials that you should provide
                    below:
                  </Text>
                  <Spacer height={theme.spacing.spacing03} />
                  <FormGroup error={errors[FormField.appId]}>
                    <Label htmlFor={FormField.appId} name="App ID" required />
                    <TransformableInput
                      name={FormField.appId}
                      transform={trimTransform}
                      control={control}
                      placeholder="e.g. 291bba3f-e0a5-47bc-a099-3dbcb2a50a05"
                    />
                  </FormGroup>
                  <Spacer height={theme.spacing.spacing03} />
                  <FormGroup error={errors[FormField.password]}>
                    <Label
                      htmlFor={FormField.password}
                      name="Password"
                      required
                    />
                    <TransformableInput
                      type="password"
                      name={FormField.password}
                      transform={trimTransform}
                      control={control}
                      placeholder=""
                    />
                  </FormGroup>
                  <Spacer height={theme.spacing.spacing03} />
                  <FormGroup error={errors[FormField.tenantId]}>
                    <Label
                      htmlFor={FormField.tenantId}
                      name="Tenant"
                      required
                    />
                    <TransformableInput
                      name={FormField.tenantId}
                      transform={trimTransform}
                      control={control}
                      placeholder="e.g. 291bba3f-e0a5-47bc-a099-3dbcb2a50a05"
                    />
                  </FormGroup>
                </StepContainer>
              </form>
            </FormProvider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
