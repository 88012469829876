/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { EventStatus, IncomingEventStatus } from "~/generated/graphql";
import { PopoverControls } from "~/components";
import { RevokeIncomingEvent } from "../../components/RevokeIncomingEvent/RevokeIncomingEvent";
import { RefetchOptions } from "../../helpers";

type Props = {
  eventId: string;
  status?: EventStatus | IncomingEventStatus;
  size?: "large" | "small";
  refetchQueries: RefetchOptions;
};

export function EventActions({
  eventId,
  status,
  size = "small",
  refetchQueries,
}: Props) {
  type State = {
    isRevokeIncomingEventModalOpen: boolean;
  };

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => {
      return {
        ...data,
        ...partialData,
      };
    },
    { isRevokeIncomingEventModalOpen: false }
  );
  const isUpcomingEvent = () => {
    return status === EventStatus.Upcoming;
  };
  return (
    <>
      <PopoverControls
        disabled={false}
        size={size}
        items={[
          {
            label: `Revoke Event`,
            onClick: () =>
              updateState({ isRevokeIncomingEventModalOpen: true }),
            disabled: !isUpcomingEvent(),
          },
        ]}
      />
      <RevokeIncomingEvent
        eventId={eventId}
        isOpen={state.isRevokeIncomingEventModalOpen}
        onClose={() => updateState({ isRevokeIncomingEventModalOpen: false })}
        refetchQueries={refetchQueries}
      />
    </>
  );
}
