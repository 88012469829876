/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { theme, Label, Dropdown, Text } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { timezones } from "~/constants/timezones";
import { FormField } from "../types";

export function ScheduleTimezone() {
  const formMethods = useFormContext();
  const {
    control,
    formState: { errors },
    clearErrors,
  } = formMethods;

  return (
    <FormGroup error={errors[FormField.SCHEDULE_TIMEZONE]}>
      <Label css={{ marginBottom: 0 }} name="Plan Timezone" required />
      <Text
        mb={theme.spacing.spacing02}
        size="sm"
        color={theme.color.text.text02}
      >
        By default local timezone will be displayed. You can change it to:
      </Text>
      <Controller
        control={control}
        name="scheduleTimezone"
        render={({ field: { onChange, value } }) => {
          return (
            <Dropdown
              name="Select Timezone"
              options={timezones}
              onChange={(val: string) => {
                clearErrors();
                onChange(val);
              }}
              value={value}
              minNumOfOptionsToShowSearchBox={10}
            />
          );
        }}
      />
    </FormGroup>
  );
}
