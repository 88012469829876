/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CopyToClipboard } from "react-copy-to-clipboard";
import { If, Then, Else, When } from "react-if";
import { Link } from "react-router-dom";
import { FlexContainer, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { SUCCESS_TEXT } from "~/constants";
import { showInfo } from "~/services/toast";

type Props = {
  name: string;
  id: string;
  link?: string;
  copy?: boolean;
};

export function NameWithIdCell({ id, name, link, copy = false }: Props) {
  return (
    <FlexContainer
      justifyContent="flex-start"
      alignItems="flex-start"
      direction="column"
    >
      <If condition={link}>
        <Then>
          <Link to={link ?? ""}>{name}</Link>
        </Then>
        <Else>
          <div>{name}</div>
        </Else>
      </If>
      <FlexContainer alignItems="center">
        <Text
          size="sm"
          tag="span"
          mr={theme.spacing.spacing01}
          weight="regular"
          color={theme.color.text.text02}
        >
          {id}
        </Text>
        <When condition={copy}>
          <CopyToClipboard
            text={id}
            onCopy={() => {
              showInfo(SUCCESS_TEXT.copied);
            }}
          >
            <SVGIcon
              name="copy"
              size="sm"
              style={{
                cursor: "pointer",
                marginLeft: theme.spacing.spacing02,
              }}
            />
          </CopyToClipboard>
        </When>
      </FlexContainer>
    </FlexContainer>
  );
}
