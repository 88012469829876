// Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.

import { CloudAccount } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";
import { stringRequired } from "~/components/Forms";
import { awsRegions } from "~/constants";
import { useUpdateCloudAccount } from "../hooks";
import { DisplayRegions } from "./components/DisplayRegions";

export const HeaderPreset: (cloud?: CloudAccount) => DetailData[] = (
  cloudAccount
) => [
  {
    label: "Cloud Account Name",
    value: cloudAccount?.name ?? cloudAccount?.id,
    editProps: {
      property: "name",
      updateHook: useUpdateCloudAccount,
      schema: {
        name: stringRequired("Name"),
      },
    },
  },
];

export const DetailsFirstPreset: (cloud?: CloudAccount) => DetailData[] = (
  cloudAccount
) => {
  return [
    {
      label: "Account ID",
      value: cloudAccount?.providerId,
      clipboard: true,
    },
    {
      label: "Regions",
      value: cloudAccount?.regions,
      renderComponent: DisplayRegions,
      editProps: {
        property: "regions",
        updateHook: useUpdateCloudAccount,
        selectOptions: awsRegions.map((region) => {
          return {
            value: region,
            label: region,
          };
        }),
        minWidth: "80%",
      },
    },
    {
      label: "Description",
      value: cloudAccount?.description,
      editProps: {
        property: "description",
        updateHook: useUpdateCloudAccount,
        schema: {
          description: stringRequired("Description"),
        },
      },
    },
  ];
};

export const DetailsColumnSecond: (cloud?: CloudAccount) => DetailData[] = (
  cloudAccount
) => [
  {
    label: "Environment",
    value: cloudAccount?.environment,
    editProps: {
      property: "environment",
      updateHook: useUpdateCloudAccount,
      schema: {
        environment: stringRequired("Environment"),
      },
    },
  },
];
