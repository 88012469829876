/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Text } from "@nordcloud/gnui";
import { Provider } from "~/generated/graphql";

type Props = PropsWithChildren<{
  to: string;
  provider: string;
}>;

export function NameCell({ to, provider, children }: Props) {
  return (
    <>
      <Link to={to}>{children}</Link>
      <Text tag="div" size="sm">
        Compute: {provider === Provider.Aws ? "EC2" : "Azure Virtual Machines"}
      </Text>
    </>
  );
}
