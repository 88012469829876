/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Row, createColumnHelper } from "@tanstack/react-table";
import { Text, theme } from "@nordcloud/gnui";
import {
  EventActionResourceState,
  Group,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { MediumIconColumn, SmallIconColumn } from "~/components/Tables/styles";
import { TooltipIconStatus } from "./TooltipIconStatus";
import { getResourceStatusesCount, getTotalResourceCount } from "./utils";

export type EventActions = {
  name: string;
  resourceStates: EventActionResourceState[];
  resourceGroups: Group[];
  globalState: {
    status: string;
  };
};

const columnHelper = createColumnHelper<EventActions>();

export function eventActionsColumns() {
  const resourcesCount = (row: Row<EventActions>) => {
    return (
      getResourceStatusesCount(row.original.resourceStates ?? [], [
        ...Object.values(ResourceExecutionStatus),
      ]) ??
      getTotalResourceCount(row.original.resourceGroups) ??
      "-"
    );
  };

  return [
    columnHelper.accessor(() => null, {
      header: "",
      id: "dummy",
      meta: SmallIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "",
      id: "state",
      cell: ({ row }) => {
        return (
          <TooltipIconStatus
            status={row?.original?.globalState?.status}
            type="Action"
          />
        );
      },
      meta: MediumIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "Action Name",
      cell: ({ row }) => {
        return <div>{row.original.name}</div>;
      },
      meta: {
        css: {
          width: "100%",
          minWidth: "20rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Number of resources",
      cell: ({ row }) => {
        return <div>{resourcesCount(row)}</div>;
      },
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Successful",
      cell: ({ row }) => {
        return (
          <Text color="success" mb={0}>
            {getResourceStatusesCount(row.original.resourceStates, [
              ResourceExecutionStatus.Success,
              ResourceExecutionStatus.ManualSuccess,
            ]) ?? "-"}
          </Text>
        );
      },
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Unsuccessful",
      cell: ({ row }) => {
        return (
          <Text color="danger" mb={0}>
            {getResourceStatusesCount(row.original.resourceStates, [
              ResourceExecutionStatus.Failed,
              ResourceExecutionStatus.Error,
              ResourceExecutionStatus.Timeout,
              ResourceExecutionStatus.NotInTimeWindow,
              ResourceExecutionStatus.InProgress,
              ResourceExecutionStatus.Pending,
            ]) ?? "-"}
          </Text>
        );
      },
      meta: {
        css: {
          minWidth: "8.75rem",
          padding: theme.spacing.spacing03,
        },
      },
    }),
  ] as never;
}
