/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { parseExpression } from "cron-parser";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FlexContainer,
  SelectButton,
  Sidebar,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DetailWrapper, ValueWrapper } from "~/components/Details/styles";
import { useToggle } from "~/hooks";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { StyledMultiSelect } from "~/views/events/components/DateRangeSelector/styles";
import { convertToMinutes, getNextDate } from "~/views/plans/utils";
import { useUpdatePlan } from "../../hooks/useUpdatePlan/useUpdatePlan";
import { ScheduleCron } from "../../PlanCreate/components/PlanCreateWizard/PlanScheduleForms/ScheduleCron";
import { RadioCheckbox } from "../../PlanCreate/components/PlanCreateWizard/PlanScheduleForms/SelectSchedule";
import { TimeUnits } from "../../PlanCreate/components/PlanCreateWizard/types";
import { Schedule } from "../../types";
import { usePlan } from "../PlanProvider";

export enum FormField {
  SCHEDULE_CRON = "scheduleCron",
  SCHEDULE_TIMEZONE = "scheduleTimezone",
  SCHEDULE_OFFSET = "scheduleOffset",
  SCHEDULE_OFFSET_UNIT = "scheduleOffsetUnit",
}
export type FormData = {
  [FormField.SCHEDULE_TIMEZONE]?: string;
  [FormField.SCHEDULE_OFFSET]?: number;
  [FormField.SCHEDULE_OFFSET_UNIT]?: TimeUnits;
  [FormField.SCHEDULE_CRON]?: string;
};

const scheduleKind = [
  {
    name: "Schedule options",
    label: "Specify the time or intervals to run the plan.",
    scope: Schedule.repeated,
    disabled: false,
  },
  {
    name: "Run Manually",
    label: "You can run plan later.",
    scope: Schedule.runManually,
    disabled: true,
  },
];

const scheduleButtons = [
  {
    label: "Repeated",
    value: Schedule.repeated,
  },
  {
    label: "Run Once on Time",
    value: Schedule.runOnce,
  },
];

export function CronDetails() {
  const [isExpanded, toggleIsExpanded] = useToggle();
  const [scheduleType, setScheduleType] = useState<Schedule>(Schedule.repeated);
  const { plan } = usePlan();

  const planData = {
    details: undefined,
    schedule_plan: undefined,
    plan_settings: {
      scheduleTimezone: plan?.scheduleTimezone,
      scheduleCron: plan?.scheduleCron,
      scheduleOffset: plan?.scheduleOffset ?? 0,
      scheduleOffsetUnit: TimeUnits.minutes,
    },
    general_notifications: undefined,
  };

  const formMethods = useForm({
    defaultValues: {
      scheduleOffsetUnit: TimeUnits.minutes,
    },
  });

  const { handleSubmit } = formMethods;

  const success = () => {
    toggleIsExpanded();
    showSuccess(generateActionSuccessText("Plan")()("updated")());
  };

  const [updatePlan, loading] = useUpdatePlan({
    onSuccess: success,
  });

  const onSubmit = (data: FormData) => {
    updatePlan({
      id: plan?.id ?? "",
      scheduleCron: data.scheduleCron,
      scheduleOffset: convertToMinutes(
        data.scheduleOffset?.toString() ?? "",
        data.scheduleOffsetUnit ?? TimeUnits.minutes
      ),
    });
  };

  const { scheduleTimezone, scheduleOffset } = plan || {};
  const scheduleCron = plan?.scheduleCron ?? "";
  const parseOptions = { tz: scheduleTimezone };

  const displayParsedDate = parseExpression(scheduleCron, parseOptions);

  const scheduleOffsetInMinutes = convertToMinutes(
    scheduleOffset?.toString() ?? "",
    TimeUnits.minutes
  );

  return (
    <>
      <DetailWrapper>
        <FlexContainer alignItems="flex-start" direction="column">
          <Text
            tag="div"
            size="sm"
            color={theme.color.text.text02}
            css={{ textDecoration: "none" }}
          >
            Next execution
          </Text>
          <ValueWrapper editable isEmpty={false}>
            <div data-testid="Plan Cron">
              {displayParsedDate
                ? getNextDate(displayParsedDate, scheduleOffsetInMinutes)
                : "Time not defined"}
            </div>
          </ValueWrapper>
        </FlexContainer>
        <Button
          aria-label="edit button"
          icon="edit"
          size="md"
          severity="low"
          ml={theme.spacing.spacing02}
          onClick={toggleIsExpanded}
          css={{ visibility: "hidden" }}
        />
      </DetailWrapper>
      <Sidebar
        isOpen={isExpanded}
        title="Schedule Plan"
        onClick={toggleIsExpanded}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box boxStyle="lightGrey">
              <RadioCheckbox
                name={scheduleKind[0].name}
                label={scheduleKind[0].label}
                scope={scheduleKind[0].scope}
                onScopeChange={setScheduleType}
                disabled={scheduleKind[0].disabled}
              />
              <StyledMultiSelect
                size="small"
                css={{
                  margin: `0 0 ${theme.spacing.spacing04} ${theme.spacing.spacing07}`,
                }}
              >
                {scheduleButtons.map((type) => {
                  return (
                    <SelectButton
                      key={type.value}
                      name={type.value}
                      value={type.value}
                      labelText={type.label}
                      isActive={scheduleType === type.value}
                      onClick={() => setScheduleType(type.value)}
                      disabled={type.value === Schedule.runOnce}
                    />
                  );
                })}
              </StyledMultiSelect>
              <ScheduleCron planData={planData} />
              <RadioCheckbox
                name={scheduleKind[1].name}
                label={scheduleKind[1].label}
                scope={scheduleKind[1].scope}
                onScopeChange={setScheduleType}
                disabled={scheduleKind[1].disabled}
              />
            </Box>
            <Button
              mt={theme.spacing.spacing04}
              type="submit"
              disabled={loading}
              initialState={loading ? "loading" : ""}
            >
              Apply
            </Button>
          </form>
        </FormProvider>
      </Sidebar>
    </>
  );
}
